import React from 'react';

import personal from 'data/personal.yaml';

const {
  name, position, caption, image,
} = personal[0];

const Persone = () => (
  <div className="person">
    <img src={image} alt="" className="person__image" />
    <div className="person__info">
      <strong className="person__name">{name}</strong>
      <span className="person__position">{position}</span>
      <span className="person__caption">{caption}</span>
    </div>
  </div>
);

export default Persone;
