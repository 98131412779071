import Media from 'react-media';
import React from 'react';
import { Tween } from 'react-gsap';
import { useStaticQuery, graphql } from 'gatsby';

import screen from 'utils/screen';

import Container from 'components/layout/container';
import Person from 'ui/person';
import SpeechBubble from 'ui/speech_bubble';

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
        }
      }
    }
  `);

  const { description } = data.site.siteMetadata;

  return (
    <div className="hero">
      <Media queries={{
        md: screen.md,
      }}
      >
        {(matches) => (
          <>
            {matches.md && (
              <Tween
                from={{ rotationY: 360, transformOrigin: 'center' }}
                ease="Back.easeOut"
                transition={0.3}
                delay={1}
              >
                <span className="hero__image" />
              </Tween>
            )}

            <Container>
              <div className="hero__inner">

                <h1 className="hero__title">
                  <Tween
                    staggerFrom={{ x: '-100', opacity: 0, color: '#EBFF8B' }}
                    delay={0.5}
                    duration={0.2}
                    ease="Back.easeOut"
                  >
                    <span className="title__transition">бухгалтерия</span>
                  </Tween>
                  <br />
                  <Tween
                    staggerFrom={{ x: '-100', opacity: 0, color: '#EBFF8B' }}
                    delay={0.5}
                    duration={0.7}
                    ease="Back.easeOut"
                  >
                    <strong className="title__transition">нового поколения</strong>
                  </Tween>
                </h1>

                <div className="hero__presentation">
                  {!matches.md && (
                    <Tween
                      from={{ rotationY: 360, transformOrigin: 'center' }}
                      ease="Back.easeOut"
                      transition={0.3}
                      delay={1}
                    >
                      <span className="hero__image" />
                    </Tween>
                  )}
                  <SpeechBubble>{description}</SpeechBubble>

                  <Person />
                </div>

              </div>

            </Container>
          </>
        )}
      </Media>
    </div>
  );
};
export default Hero;
