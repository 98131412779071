import React from 'react';

import Hero from 'components/blocks/hero';
import Layout from 'components/layout';
import SEO from 'components/seo';

const IndexPage = () => (
  <Layout location="/">
    <SEO />

    <Hero />

  </Layout>

);

export default IndexPage;
